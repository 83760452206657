import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Favicon } from '../page/Favicon';
import { Footer } from '../page/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/style.scss';
import { Header } from '../page/Header';
import Intercom from 'react-intercom';

const DefaultLayout = (props) => {
  const [showIntercom, setIntercom] = useState(false);
  setTimeout(() => {
    setIntercom(true);
  }, 6000);
  return (
    <div>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={'Breakaway Club | Potters Resorts'}
        meta={[
          { name: 'description', content: 'Breakaway Club -- Member discounts on short breaks at Potters Resorts.' },
          { property: 'og:title', content: 'Breakaway Club | Potters Resorts' },
          { property: 'og:description', content: 'Member discounts on short breaks at Potters Resorts.' }
        ]}
        link={[
          { rel: 'shortcut icon', type: 'image/x-icon', href: `${Favicon}` }
        ]}
      />
      <div id="page-background">
        <div id="page-boxed" style={props.styles ? props.styles : {}}>
      <Header/>
      {props.children}
      {/* <Footer/> */}
      {showIntercom && <Intercom appID='rinyr2jd'/>}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
